@charset "UTF-8";
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  font-size: 14px;
}

md-tab-content.md-no-scroll {
  overflow: auto;
}

.header-right {
  float: right;
  height: 126px;
  padding-top: 50px;
  box-sizing: border-box;
}

.header-right img {
  width: 60px;
  padding: 0 5px;
  display: inline-block;
}

.header-right img.allianz {
  width: 90px;
}

.read-only {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.read-only p {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 50px;
  line-height: 60px;
  margin: -30px 0 0 0;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

/*md-tabs-content-wrapper {
  background: #f6f6f6 none repeat scroll 0 0;
}*/
md-toolbar h2 {
  color: white;
  width: 100%;
}

.sub-text {
  margin: -15px 0 0 0;
  font-size: 11px;
}

.logo {
  max-width: 200px;
  padding: 15px 0 7px 0;
}

.cke_chrome {
  box-shadow: 0 0 0 !important;
}

.datepicker-helper {
  position: relative;
  margin-top: 14px;
}

.datepicker-helper .md-button.md-icon-button {
  margin-left: -4px;
  padding-left: 0;
}

.datepicker-helper .fs-label {
  position: absolute;
  top: -7px;
  left: 0;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.54);
}

.datepicker-helper .md-datepicker-input-container {
  width: calc(100% - 45px);
  margin-left: 0;
}

.fs-form-inline-button {
  margin: -6px 0 0 0;
  width: 100%;
}

.fs-form-inline-button-2 {
  margin: -6px 0 0 0;
  width: 48%;
}

.fs-radio {
  margin: 0 5px 10px 0 !important;
  line-height: 32px;
}

.fs-radio-title {
  margin: 0 !important;
  line-height: 32px;
}

.fs-radio-group-title {
  position: relative;
  top: -35px;
  left: 12px;
}

.fs-icon-in-form, .fs-icon-in-form button {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

body .cke_toolbar_break {
  display: none;
}

md-tab-content {
  min-height: 500px;
}

.ts-id {
  float: right;
  color: white !important;
}

.ts-id md-icon {
  color: white !important;
}

.ts-id a:hover {
  text-decoration: underline;
}

mdp-date-picker md-input-container, mdp-time-picker md-input-container {
  width: calc(100% - 50px);
}

/*mdp-date-picker md-input-container::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}*/
.mdp-datepicker-date {
  display: none;
}

.mdp-datepicker-year {
  position: relative;
  width: 110px;
  background: #b71c1c;
  text-align: center;
  border-radius: 15px;
  padding: 5px 0;
  transition: color 0.5s ease;
  color: white;
  font-weight: bold;
  opacity: 1;
  text-transform: uppercase;
}

.mdp-datepicker-year:hover {
  background: rgba(183, 28, 28, 0.8);
}

/*.mdp-datepicker-year::before {
  content: 'keyboard_arrow_left';
  font-family: 'Material Icons';
  font-size: 24px;
  position: relative;
  top: 6px;
}

.mdp-datepicker-year::after {
  content: 'keyboard_arrow_right';
  font-family: 'Material Icons';
  font-size: 24px;
  position: relative;
  top: 6px;
}*/
.mdp-datepicker-year::before {
  content: "Year ";
}

.mdp-datepicker-year::after {
  content: '›';
  font-size: 30px;
  line-height: 12px;
  top: 3px;
  position: relative;
  padding-left: 8px;
}

.optional input, .optional textarea {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAABRJREFUCB1jYEAC/4EAziWSA1QPAB5CD/E43tuSAAAAAElFTkSuQmCC");
  background-color: #f8f8f8;
}

.header {
  margin: 0 8px;
}

.fs-send-btn {
  width: 100%;
  margin: 4px 0 0 0;
}

md-card md-card-content {
  min-height: 160px;
}

@media print {
  md-tabs-wrapper, md-toolbar, button, .read-only {
    display: none !important;
  }
}

@media screen and (max-width: 560px) {
  .header-right {
    display: none !important;
  }
}
